<template>
    <div class="content-wrapper">
        <rq-page-section title="Batch Invoice Payments" headerSize="lg" borderless>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <b-btn automation_id="btn_clear_search" variant="theme" :disabled="readOnly" @click="onClearSearch">Clear Search</b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn automation_id="btn_search" variant="theme" :disabled="readOnly || v$.$error" @click="onSearch">Search</b-btn>
                    </li>
                </ul>
            </template>
            <!-- <template #header-secondary>
                <router-link automation_id="btn_view_order_loans" :to="{ name:'o-inv:invoices', params: { orderId } }" class="btn btn-link btn-theme">View Invoice List</router-link>
            </template> -->
            <fieldset>
                <div class="row">
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="customer_company">Customer/Invoicee</label>
                        <company-picker
                            ref="customerCompanyPicker"
                            automation_id="pic_customer_company"
                            dialogTitle="Select Customer"
                            :disabled="readOnly"
                            v-model="customerCompany">
                        </company-picker>
                    </div>
                    <div :class="{ 'col col-3 col-sm-12 col-md-6 col-lg-3 form-group':true, 'has-error':v$.startDate.$error }">
                        <label for="dtp_start_date">Start Date</label>
                        <rqdx-date-box
                            id="dtp_start_date"
                            type="date"
                            v-model="v$.startDate.$model"
                            :disabled="readOnly"
                        />
                        <rq-validation-feedback
                            :messages="{ 'Start Date cannot be after End Date': v$.startDate.isInRange.$invalid }"
                        />
                    </div>
                    <div :class="{ 'col col-3 col-sm-12 col-md-6 col-lg-3 form-group':true, 'has-error':v$.endDate.$error }">
                        <label for="dtp_end_date">End Date</label>
                        <rqdx-date-box
                            id="dtp_end_date"
                            type="date"
                            v-model="v$.endDate.$model"
                            :disabled="readOnly"
                        />
                        <rq-validation-feedback
                            :messages="{ 'End Date cannot be before Start Date': v$.endDate.isInRange.$invalid }"
                        />
                    </div>
                </div>
            </fieldset>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="tbl_invoices"
            :actions="selectionActions"
            :data-source="gridDataSource"
            :config="gridConfig"
            export-file-name="invoice-data"
            @select="onGridSelection"
            integrated-search
            rq-filters
        />
    </div>
</template>
<script>
    import { DateTime } from "luxon";
    import { useVuelidate } from "@vuelidate/core";
    import { mapState } from "vuex";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { SearchRequest } from '@/shared/models/models';
    import { CompanyPicker } from '@/shared/components/rq';
    import BatchInvoicePaymentDialog from '../components/BatchInvoicePaymentDialog';

    export default {
        name: "BatchInvoicePaymentSearch",
        components: { CompanyPicker },
        setup: () => ({ v$: useVuelidate() }),
        data(){
            return{
                invoices: [],
                selectedInvoices: [],
                customerCompanyID: null,
                customerCompanyName: null,
                startDate: null,
                endDate: null
            }
        },
        computed:{
            ...mapState({
                orderId: state => state.orders.orderId,
                isReadOnly: state => _.parseBool(state.isPageReadOnly),
                isOrderLocked: state => _.getBool(state, "orders.orderSummary.isLocked")
            }),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", {}); },
            readOnly() { return this.isReadOnly || this.isOrderLocked; },
            customerCompany: {
                get:function(){
                    return {
                        companyID: this.customerCompanyID,
                        companyName: this.customerCompanyName,
                        contactID: null,
                        contactName: null
                    }
                },
                set:function(val){
                    this.customerCompanyID = _.get(val, "companyID", null);
                    this.customerCompanyName = _.get(val, "companyName", null);
                }
            },
        },
        created() {
            const self = this;
            self.initDates();
            self.loadGridActions();
            self.loadGridConfig();
            self.fetchData();
        },
        validations() {

            var result = {
                startDate: {
                    isInRange(val) {
                        if (_.isNullOrEmpty(val) || _.isNullOrEmpty(this.endDate)) return true;
                        let formattedEndDate = this.endDate;
                        const parsedDate = DateTime.fromFormat(this.endDate, "MM/dd/yyyy");
                        if(parsedDate.isValid)
                        {
                            formattedEndDate = parsedDate.toISO();
                        }
                        let daysDiff = DateTime.fromISO(val).startOf("day").diff(DateTime.fromISO(formattedEndDate).startOf("day"), "days").days;
                        return daysDiff <= 0;
                    }
                },
                endDate: {
                    isInRange(val) {
                        if (_.isNullOrEmpty(val) || _.isNullOrEmpty(this.startDate)) return true;
                        let formattedStartDate = this.startDate;
                        const parsedDate = DateTime.fromFormat(this.startDate, "MM/dd/yyyy");
                        if(parsedDate.isValid)
                        {
                            formattedStartDate = parsedDate.toISO();
                        }
                        let daysDiff = DateTime.fromISO(val).startOf("day").diff(DateTime.fromISO(formattedStartDate).startOf("day"), "days").days;
                        return daysDiff >= 0;
                    }
                },
            }
            return result;
        },
        methods:{
            loadGridConfig() {
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "fileNumber",
                            caption: "File Number",
                            dataType: "string",
                            editorOptions:{ inputAttr: {automation_id: "txt_file_number"} },
                        },
                        {
                            dataField: "invoiceID",
                            caption: "Invoice ID",
                            dataType: "number",
                            editorOptions:{ inputAttr: {automation_id: "txt_invoice_id"} },
                        },
                        DxGridUtils.dateColumn({
                            dataField: "invoiceDate",
                            dataType: "datetime",
                            format: "MM/dd/yyyy",
                            editorOptions:{ inputAttr: {automation_id: "txt_invoice_date"} },
                            rqFilter: {
                                disabled: true,
                                disabledTooltip: "Use fields at the top of the page to filter this column"
                            }
                        }),
                        {
                            dataField: "balance",
                            caption: "Balance",
                            alignment: "right",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions:{ inputAttr: {automation_id: "txt_balance"} },
                        },
                        {
                            dataField: "payment",
                            caption: "Prior Payments",
                            alignment: "right",
                            dataType: "number",
                            editorOptions:{ inputAttr: {automation_id: "txt_payment"} },
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "note1",
                            caption: "Notes",
                            dataType: "string",
                            editorOptions:{ inputAttr: {automation_id: "txt_notes"} },
                        },
                        {
                            dataField: "customerCompanyName",
                            caption: "Customer/Invoicee",
                            dataType:"string",
                            editorOptions:{ inputAttr: {automation_id: "txt_customer_invoicee"} },
                            rqFilter: {
                                disabled: true,
                                disabledTooltip: "Use fields at the top of the page to filter this column"
                            }
                        },

                    ],
                    paging: { enabled: true, pageIndex: 0, pageSize: 50 },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,150], showInfo: true},
                };

                self.gridDataSource = {
                    key: "invoiceID",
                    load: () => Promise.resolve(self.invoices)
                };
            },

            loadGridActions() {
                const self = this;
                self.selectionActions = [
                    { name: "apply-batch-payment", text: "Apply Batch Payment", eventName: "select", requireSelection: false },
                ];
            },

            initDates() {
                this.startDate = DateTime.now().startOf('year').toFormat('MM/dd/yyyy');
                this.endDate = DateTime.now().toFormat('MM/dd/yyyy');
            },

            fetchData(loadOptions){
                const self = this;
                let request = self.getCurrentSearchRequest(loadOptions);

                if(!request.searchTerm)
                    return Promise.resolve({data: [], totalCount: 0});

                let promise = self.$api.InvoiceApi.batchPaymentSearch(request);
                return self.$rqBusy.wait(promise)
                    .then(response => {
                        self.invoices = response.results;
                    })
                    .catch(error => {
                        self.$toast.error("Search Failed.");
                        console.error("Search Failed.", error);
                        return { data:[], totalCount: 0 };
                    })
                    .finally(()=>{
                        self.refresh();
                    });

            },

            getCurrentSearchRequest (loadOptions) {
                const self = this;
                var searchParams = {};
                searchParams["startDate"] = _.isNullOrEmpty(self.startDate) ? null : self.startDate;
                searchParams["endDate"] = _.isNullOrEmpty(self.endDate) ? null : self.endDate;

                let request = new SearchRequest({
                    searchTerm: _.isNullOrEmpty(self.customerCompanyID) ? "0" : self.customerCompanyID,
                    parameters: searchParams,
                });
                request.parseLoadOptions(loadOptions);
                return request;
            },

            onClearSearch(){
                this.customerCompanyID = null;
                this.customerCompanyName = null;
                this.startDate = null;
                this.endDate = null;
                this.refresh();
                this.onSearch();
            },

            onSearch(){
                this.fetchData();
            },

            onGridSelection(e){
                if(!e || !e.data) return;
                const self = this;
                self.selectedInvoices = [];

                _.forEach(e.data, item => {
                    let invoice = _.find(self.invoices, i => { return i.invoiceID === item.invoiceID;});
                    self.selectedInvoices.push(_.cloneDeep(invoice));
                });

                self.showBatchInvoicePaymentDialog();

            },

            showBatchInvoicePaymentDialog(){
                const self = this;
                self.$dialog.open({
                    title: "Apply Batch Payment",
                    width: "90%",
                    height: "80%",
                    component: BatchInvoicePaymentDialog,
                    props:{
                        selectedInvoices: self.selectedInvoices
                    },
                    onOk: function(e) {
                        e.component.saveEditData();

                        let remainder = e.component.remainder;
                        let areFundsInsufficient = e.component.areFundsInsufficient;
                        let message = "Are you sure you would like to apply the payments to the selected invoices?"
                        if(areFundsInsufficient){
                            message = " Invoices selected exceed the Check Amount. Are you sure you would like to apply the payments to the selected invoices?";
                        }
                        let promise;
                        if(remainder === 0){
                            promise = new Promise(function(resolve, reject){
                                self.$dialog.confirm("Confirm Batch Payment", message,
                                ()=>{
                                    self.applyBatchPayment(e.component.checkAmount, e.component.referenceNumber);
                                    resolve(true);
                                }, ()=>{
                                    resolve(false);
                                });
                            });
                        }
                        if(remainder > 0){
                            promise = new Promise(function(resolve, reject){
                                self.$dialog.confirm("Confirm Batch Payment", "Funds have not been exhausted. Are you sure you want to apply the payments to the selected invoices?",
                                ()=>{
                                    self.applyBatchPayment(e.component.checkAmount, e.component.referenceNumber);
                                    resolve(true);
                                }, ()=>{
                                    resolve(false);
                                });
                            });
                        }
                        if(remainder < 0){
                            return Promise.resolve(false);
                        }
                        return promise;
                    },
                });
            },

            applyBatchPayment(checkAmount, referenceNumber){
                const self = this;
                let promise = self.$api.InvoiceApi.applyBatchPayments(self.selectedInvoices, checkAmount, referenceNumber).then(() => {
                    self.$toast.success("Batch Payment Applied Successfully");
                    self.fetchData();
                }).catch(error => {
                    self.$toast.error(`Batch Payment Failed: ${error}`);
                    console.error("Search Failed.", error);
                    return false;
                });
                self.$rqBusy.wait(promise);
                return promise;
            },

            refresh() {
                if(!this.gridInstance) return;
                this.gridInstance.refresh();
                this.gridInstance.clearSelection();
            },
        }
    }
</script>